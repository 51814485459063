import React from 'react';
import { Button, Col, Form, Input, Row, Select, Typography, theme } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const NRIForm = () => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();

  // Custom styles
  const customStyle = {
    formContainer: {
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      padding: '10px',
    },
    input: {
      fontFamily: 'Poppins',
      fontSize: '12px',
    },
    heading: {
      padding: '10px 0',
      background: token.colorFillSecondary,
      borderRadius: token.borderRadiusLG,
      textAlign: 'right',
      padding: '12px',
      maxWidth: '100%',
      margin: '12px'
    },
    title: {
      margin: 0,
      fontFamily: 'Poppins',
      fontSize: '14px'
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '12px'
    },
  };

  // Handle form submit
  const handleSubmit = (values) => {
    // Log the form data to the console
    console.log('Form Submitted:', values);
    
    // You can also save the data here, e.g., by calling an API or saving to state
  };

  const getFields = () => {
    return (
      <>
        {/* Heading Row */}
        <Row justify="space-between" style={customStyle.heading}>
          <Col>
            <Text level={4} style={customStyle.title}>NRI Details</Text>
          </Col>
          <Col>
            <Text style={customStyle.stepText}>Step 2 of 6</Text>
          </Col>
        </Row>

        {/* Address Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="address1"
              label={<span style={customStyle.label}>Address 1</span>}
              rules={[{ required: true, message: 'Please input Address 1!' }]}>
              <Input placeholder="Address 1" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address2"
              label={<span style={customStyle.label}>Address 2</span>}>
              <Input placeholder="Address 2" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Address Row 2 */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="address3"
              label={<span style={customStyle.label}>Address 3</span>}>
              <Input placeholder="Address 3" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={<span style={customStyle.label}>City</span>}
              rules={[{ required: true, message: 'Please input City!' }]}>
              <Input placeholder="City" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* State and Country Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="state"
              label={<span style={customStyle.label}>State</span>}
              rules={[{ required: true, message: 'Please input State!' }]}>
              <Input placeholder="State" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              label={<span style={customStyle.label}>Country</span>}
              rules={[{ required: true, message: 'Please select Country!' }]}>
              <Select placeholder="Select Country" style={customStyle.input}>
                <Option value="india">India</Option>
                <Option value="usa">USA</Option>
                <Option value="uk">UK</Option>
                {/* Add more country options as needed */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Pincode Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="pincode"
              label={<span style={customStyle.label}>Pincode</span>}
              rules={[{ required: true, message: 'Please input Pincode!' }]}>
              <Input placeholder="Pincode" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </>
    );
  };

  return (
    <Form form={form} layout="vertical" style={customStyle.formContainer} onFinish={handleSubmit}>
      {getFields()}
    </Form>
  );
};

export default NRIForm;
