import React,{useState} from 'react';
import { Button, Col, Form, Input, Row, Typography, theme } from 'antd';

const { Text } = Typography;

const BankForm = () => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  // Custom styles
  const customStyle = {
    formContainer: {
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      padding: '10px',
    },
    input: {
      fontFamily: 'Poppins',
      fontSize: '12px',
    },
    heading: {
      padding: '10px 0',
      background: token.colorFillSecondary,
      borderRadius: token.borderRadiusLG,
      textAlign: 'right',
      padding: '12px',
      maxWidth: '100%',
      margin: '12px'
    },
    title: {
      margin: 0,
      fontFamily: 'Poppins',
      fontSize: '14px'
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '12px'
    },
  };

  const handleSubmit = (values) => {
    console.log("Form Data Submitted: ", values);
    // You can send this data to your API or do whatever you need to with it.
    // Example: sending it to an API
    // axios.post('/api/bank-details', values);
    setFormData(values);

  };

  const getFields = () => {
    return (
      <>
        {/* Heading Row */}
        <Row justify="space-between" style={customStyle.heading}>
          <Col>
            <Text level={4} style={customStyle.title}>Bank Details</Text>
          </Col>
          <Col>
            <Text style={customStyle.stepText}>Step 4 of 6</Text>
          </Col>
        </Row>

        {/* Address Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="address1"
              label={<span style={customStyle.label}>Bank Name</span>}
              rules={[{ required: true, message: 'Please input Bank Name!' }]}
            >
              <Input placeholder="Bank Name" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address2"
              label={<span style={customStyle.label}>Account Type</span>}
            >
              <Input placeholder="Account Type" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Address Row 2 */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="account_no"
              label={<span style={customStyle.label}>Account no</span>}
            >
              <Input placeholder="Account no" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={<span style={customStyle.label}>Re Enter Account no</span>}
              rules={[{ required: true, message: 'Please input Account no!' }]}
            >
              <Input placeholder="Re Enter Account no" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* State and Country Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="state"
              label={<span style={customStyle.label}>IFSC Code</span>}
              rules={[{ required: true, message: 'Please input IFSC Code!' }]}
            >
              <Input placeholder="IFSC Code" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              label={<span style={customStyle.label}>MICR No</span>}
              rules={[{ required: true, message: 'Please input MICR No!' }]}
            >
              <Input placeholder="MICR No" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Pincode Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="branch_name"
              label={<span style={customStyle.label}>Branch Name</span>}
              rules={[{ required: true, message: 'Please input Branch Name!' }]}
            >
              <Input placeholder="Branch Name" style={customStyle.input} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="branch_address1"
              label={<span style={customStyle.label}>Branch Address 1</span>}
              rules={[{ required: true, message: 'Please input Branch Address 1!' }]}
            >
              <Input placeholder="Branch Address 1" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

         {/* Pincode Row */}
         <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="branch_address2"
              label={<span style={customStyle.label}>Branch Address 2</span>}
              rules={[{ required: true, message: 'Please input Branch Address 2!' }]}
            >
              <Input placeholder="Branch Address 2" style={customStyle.input} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="branch_address3"
              label={<span style={customStyle.label}>Branch Address 3</span>}
              rules={[{ required: true, message: 'Please input Branch Address 3!' }]}
            >
              <Input placeholder="Branch Address 3" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Mobile No */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="branch_city"
              label={<span style={customStyle.label}>Branch City</span>}
              rules={[{ required: true, message: 'Please input Branch City!' }]}
            >
              <Input placeholder="Branch City" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="branch_pincode"
              label={<span style={customStyle.label}>Branch Pincode</span>}
              rules={[{ required: true, message: 'Please input Branch Pincode!' }]}
            >
              <Input placeholder="Branch Pincode" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="branch_country"
              label={<span style={customStyle.label}>Branch Country</span>}
              rules={[{ required: true, message: 'Please input Branch Country!' }]}
            >
              <Input placeholder="Branch Country" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </>
    );
  };

  return (
    <Form 
      form={form} 
      layout="vertical" 
      style={customStyle.formContainer} 
      onFinish={handleSubmit} // Trigger handleSubmit when the form is submitted
    >
      {getFields()}
    </Form>
  );
};

export default BankForm;
