// actions/formActions.js
import { makeApiRequest } from "../../api";

// Action type
export const SUBMIT_FORM_DATA = "SUBMIT_FORM_DATA";

// Action to submit form data
export const submitFormData = (formData) => async (dispatch) => {
  try {
    // Create the payload from formData
    const payload = `
      <NMFIIService>
        <service_request>
          <appln_id>MFS153266</appln_id>
          <password>MYWAYSIP@345</password>
          <broker_code>ARN-153266</broker_code>
          <process_mode>D</process_mode>
          <title>Ms.</title>
          <inv_name>${formData.investor_name}</inv_name>
          <pan>${formData.pan}</pan>
          <valid_pan>Y</valid_pan>
          <exemption>N</exemption>
          <dob>04-Dec-1997</dob>
          <tax_status>${formData.tax_status}</tax_status>
          <kyc>Y</kyc>
          <addr1>${formData.address1}</addr1>
          <addr2>${formData.address2}</addr2>
          <city>${formData.city}</city>
          <state>${formData.state}</state>
          <pincode>${formData.pincode}</pincode>
          <country>${formData.country}</country>
          <mobile_no>${formData.mobile_no}</mobile_no>
          <email>${formData.email}</email>
          <bank_name>${formData.bank_name}</bank_name>
          <acc_no>${formData.acc_no}</acc_no>
          <acc_type>${formData.acc_type}</acc_type>
          <ifsc_code>${formData.ifsc_code}</ifsc_code>
          <branch_name>${formData.branch_name}</branch_name>
        </service_request>
      </NMFIIService>
    `;

    // Log the payload to verify all parameters
    console.log("Payload being sent to API:", payload);

    // Make the API request using the payload
    const response = await makeApiRequest("CREATECUSTOMER", payload);

    // Log the API response for debugging
    console.log("API Response:", response);

    // Dispatch the success action with the response
    dispatch({
      type: SUBMIT_FORM_DATA,
      payload: response,
    });

    // Optionally, handle additional success logic
    console.log("Form submitted successfully.");
  } catch (error) {
    // Log the error for debugging
    console.error("Error submitting form:", error);
    // Optionally, dispatch a failure action here
  }
};
