// FatcaDetails.js
import React, { useState } from "react";
import { Input, Select, Button, Form } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const FatcaDetails = ({ updateFormData }) => {
  const [fatcaStatus, setFatcaStatus] = useState("");
  const [taxResidence, setTaxResidence] = useState("");
  const [tin, setTin] = useState(""); // Taxpayer Identification Number

  const handleSubmit = () => {
    // Gather form data and pass it to the parent component (MultiStepForm)
    const stepData = {
      fatcaStatus,
      taxResidence,
      tin,
    };
    updateFormData(stepData);
  };

  return (
    <div>
      <h3>FATCA Details</h3>
      <Form layout="vertical">
        <Form.Item label="FATCA Status" required>
          <Select
            value={fatcaStatus}
            onChange={(value) => setFatcaStatus(value)}
            placeholder="Select FATCA Status"
          >
            <Option value="compliant">Compliant</Option>
            <Option value="non-compliant">Non-Compliant</Option>
            <Option value="not-applicable">Not Applicable</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Country of Tax Residence" required>
          <Input
            value={taxResidence}
            onChange={(e) => setTaxResidence(e.target.value)}
            placeholder="Enter country of tax residence"
          />
        </Form.Item>

        <Form.Item label="Taxpayer Identification Number (TIN)" required>
          <Input
            value={tin}
            onChange={(e) => setTin(e.target.value)}
            placeholder="Enter your TIN"
          />
        </Form.Item>

        <div className="form-actions">
          <Button
            type="primary"
            icon={<InfoCircleOutlined />}
            onClick={handleSubmit}
          >
            Save FATCA Details
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FatcaDetails;
