import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Checkbox, Typography, theme } from 'antd';
import '../../styles/customerForm.css'; // Import the CSS file

const { Option } = Select;
const { Text } = Typography;

const CustomerForm = ({ updateFormData }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  
  // State for form values
  const [customerDetails, setCustomerDetails] = useState({
    customerType: "",
    investorType: "",
    title: "",
    investor_name: "",
    pan: "",
    exemptionCategory: "",
    validPAN: "",
    panReferenceNo: "",
    dobDoi: "",
    holdingNature: "",
    taxStatus: "",
    occupation: "",
    kyc: "",
    dpId: "",
    investorCkyc: "",
    investorCkycRefNo: "",
    fathersName: "",
    mothersName: "",
    addModeTrxn: "",
    IINActivationMode: ""
  });

  // Custom styles
  const customStyle = {
    formContainer: {
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      padding: '10px',
    },
    input: {
      fontFamily: 'Poppins',
      fontSize: '12px',
    },
    heading: {
      padding: '12px',
      background: token.colorFillSecondary,
      borderRadius: token.borderRadiusLG,
      textAlign: 'right',
      maxWidth: '100%',
      margin: '12px'
    },
    title: {
      margin: 0,
      fontFamily: 'Poppins',
      fontSize: '14px'
    },
    stepText: {
      fontSize: '14px',
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '12px'
    },
  };

  // Handle form submit
  const handleSubmit = () => {
    console.log(customerDetails);
    updateFormData(customerDetails); // Update the parent component with form data if needed
  };

  // Function to render form fields
  const getFields = () => {
    return (
      <>
        {/* Heading Row */}
        <Row justify="space-between" style={customStyle.heading}>
          <Col>
            <Text level={4} style={customStyle.title}>Customer Details</Text>
          </Col>
          <Col>
            <Text style={customStyle.stepText}>Step 1 of 6</Text>
          </Col>
        </Row>

        {/* Customer Type and Investor Type */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="customerType"
              label={<span style={customStyle.label}>Customer Type</span>}
              rules={[{ required: true, message: 'Please select a customer type!' }]}
            >
              <Select placeholder="Select Customer Type" name="customerType" value={customerDetails.customerType} onChange={(value) => setCustomerDetails(prev => ({ ...prev, customerType: value }))} style={customStyle.input}>
                <Option value="individual">Individual</Option>
                <Option value="corporate">Corporate</Option>
                <Option value="partnership">Partnership</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="investorType"
              label={<span style={customStyle.label}>Investor Type</span>}
              rules={[{ required: true, message: 'Please select an investor type!' }]}
            >
              <Select placeholder="Select Investor Type" style={customStyle.input} name="investorType" value={customerDetails.investorType} onChange={(value) => setCustomerDetails(prev => ({ ...prev, investorType: value }))}>
                <Option value="retail">Retail</Option>
                <Option value="institutional">Institutional</Option>
                <Option value="foreign">Foreign</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Title and Investor Name */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="title"
              label={<span style={customStyle.label}>Title</span>}
              rules={[{ required: true, message: 'Please select a title!' }]}
            >
              <Select placeholder="Select Title" name="title" value={customerDetails.title} onChange={(value) => setCustomerDetails(prev => ({ ...prev, title: value }))} style={customStyle.input}>
                <Option value="mr">Mr.</Option>
                <Option value="ms">Ms.</Option>
                <Option value="mrs">Mrs.</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="investor_name"
              label={<span style={customStyle.label}>Investor as per PAN /ITD</span>}
              rules={[{ required: true, message: 'Please input investor name!' }]}
            >
              <Input placeholder="Investor Name" style={customStyle.input} name="investor_name" value={customerDetails.investor_name} onChange={(e) => setCustomerDetails(prev => ({ ...prev, investor_name: e.target.value }))} />
            </Form.Item>
          </Col>
        </Row>

        {/* PAN and Exemption Category */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="pan"
              label={<span style={customStyle.label}>PAN</span>}
              rules={[{ required: true, message: 'Please input your PAN!' }]}
            >
              <Input placeholder="PAN" style={customStyle.input} name="pan" value={customerDetails.pan} onChange={(e) => setCustomerDetails(prev => ({ ...prev, pan: e.target.value }))} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="exemptionCategory"
              label={<span style={customStyle.label}>Exemption Category</span>}
              rules={[{ required: true, message: 'Please select exemption category!' }]}
            >
              <Select placeholder="Select Category" dropdownStyle={customStyle.input} name="exemptionCategory" value={customerDetails.exemptionCategory} onChange={(value) => setCustomerDetails(prev => ({ ...prev, exemptionCategory: value }))}>
                <Option value="category1">Category 1</Option>
                <Option value="category2">Category 2</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Valid PAN and PAN Reference No. */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="validPAN"
              label={<span style={customStyle.label}>Valid PAN</span>}
              rules={[{ required: true, message: 'Please confirm if PAN is valid!' }]}
            >
              <Input placeholder="Valid PAN" style={customStyle.input} name="validPAN" value={customerDetails.validPAN} onChange={(e) => setCustomerDetails(prev => ({ ...prev, validPAN: e.target.value }))} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="panReferenceNo"
              label={<span style={customStyle.label}>PAN Reference No.</span>}
              rules={[{ required: true, message: 'Please input PAN Reference No!' }]}
            >
              <Input placeholder="PAN Reference No." style={customStyle.input} name="panReferenceNo" value={customerDetails.panReferenceNo} onChange={(e) => setCustomerDetails(prev => ({ ...prev, panReferenceNo: e.target.value }))} />
            </Form.Item>
          </Col>
        </Row>

        {/* DOB/DOI and Holding Nature */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="dobDoi"
              label={<span style={customStyle.label}>DOB/DOI as per PAN /ITD</span>}
              rules={[{ required: true, message: 'Please input your DOB/DOI!' }]}
            >
              <Input placeholder="DOB/DOI" style={customStyle.input} name="dobDoi" value={customerDetails.dobDoi} onChange={(e) => setCustomerDetails(prev => ({ ...prev, dobDoi: e.target.value }))} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="holdingNature"
              label={<span style={customStyle.label}>Holding Nature</span>}
              rules={[{ required: true, message: 'Please select holding nature!' }]}
            >
              <Select placeholder="Select Holding Nature" dropdownStyle={customStyle.input} name="holdingNature" value={customerDetails.holdingNature} onChange={(value) => setCustomerDetails(prev => ({ ...prev, holdingNature: value }))}>
                <Option value="nature1">Nature 1</Option>
                <Option value="nature2">Nature 2</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Tax Status and Occupation */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="taxStatus"
              label={<span style={customStyle.label}>Tax Status</span>}
              rules={[{ required: true, message: 'Please select tax status!' }]}
            >
              <Select placeholder="Select Tax Status" dropdownStyle={customStyle.input} name="taxStatus" value={customerDetails.taxStatus} onChange={(value) => setCustomerDetails(prev => ({ ...prev, taxStatus: value }))}>
                <Option value="status1">Status 1</Option>
                <Option value="status2">Status 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="occupation"
              label={<span style={customStyle.label}>Occupation</span>}
              rules={[{ required: true, message: 'Please select an occupation!' }]}
            >
              <Select placeholder="Select Occupation" dropdownStyle={customStyle.input} name="occupation" value={customerDetails.occupation} onChange={(value) => setCustomerDetails(prev => ({ ...prev, occupation: value }))}>
                <Option value="occupation1">Occupation 1</Option>
                <Option value="occupation2">Occupation 2</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* KYC and DP Id */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="kyc"
              label={<span style={customStyle.label}>KYC</span>}
              rules={[{ required: true, message: 'Please confirm if KYC is completed!' }]}
            >
              <Input placeholder="KYC" style={customStyle.input} name="kyc" value={customerDetails.kyc} onChange={(e) => setCustomerDetails(prev => ({ ...prev, kyc: e.target.value }))} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="dpId"
              label={<span style={customStyle.label}>DP Id</span>}
              rules={[{ required: true, message: 'Please input DP Id!' }]}
            >
              <Input placeholder="DP Id" style={customStyle.input} name="dpId" value={customerDetails.dpId} onChange={(e) => setCustomerDetails(prev => ({ ...prev, dpId: e.target.value }))} />
            </Form.Item>
          </Col>
        </Row>

        {/* Investor Ckyc and Ckyc Reference No. */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="investorCkyc"
              label={<span style={customStyle.label}>Investor Ckyc</span>}
              rules={[{ required: true, message: 'Please input investor ckyc!' }]}
            >
              <Input placeholder="Investor Ckyc" style={customStyle.input} name="investorCkyc" value={customerDetails.investorCkyc} onChange={(e) => setCustomerDetails(prev => ({ ...prev, investorCkyc: e.target.value }))} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="investorCkycRefNo"
              label={<span style={customStyle.label}>Investor Ckyc Reference No.</span>}
              rules={[{ required: true, message: 'Please input investor ckyc reference no!' }]}
            >
              <Input placeholder="Investor Ckyc Reference No." style={customStyle.input} name="investorCkycRefNo" value={customerDetails.investorCkycRefNo} onChange={(e) => setCustomerDetails(prev => ({ ...prev, investorCkycRefNo: e.target.value }))} />
            </Form.Item>
          </Col>
        </Row>

        {/* Father's and Mother's Name */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="fathersName"
              label={<span style={customStyle.label}>Father's Name</span>}
              rules={[{ required: true, message: 'Please input father\'s name!' }]}
            >
              <Input placeholder="Father's Name" style={customStyle.input} name="fathersName" value={customerDetails.fathersName} onChange={(e) => setCustomerDetails(prev => ({ ...prev, fathersName: e.target.value }))} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="mothersName"
              label={<span style={customStyle.label}>Mother's Name</span>}
              rules={[{ required: true, message: 'Please input mother\'s name!' }]}
            >
              <Input placeholder="Mother's Name" style={customStyle.input} name="mothersName" value={customerDetails.mothersName} onChange={(e) => setCustomerDetails(prev => ({ ...prev, mothersName: e.target.value }))} />
            </Form.Item>
          </Col>
        </Row>

        {/* Add Mode Transaction */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="addModeTrxn"
              label={<span style={customStyle.label}>Add Mode Transaction</span>}
              rules={[{ required: true, message: 'Please confirm transaction mode!' }]}
            >
              <Select placeholder="Select Mode" dropdownStyle={customStyle.input} name="addModeTrxn" value={customerDetails.addModeTrxn} onChange={(value) => setCustomerDetails(prev => ({ ...prev, addModeTrxn: value }))}>
                <Option value="mode1">Mode 1</Option>
                <Option value="mode2">Mode 2</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="IINActivationMode"
              label={<span style={customStyle.label}>IIN Activation Mode</span>}
              rules={[{ required: true, message: 'Please select activation mode!' }]}
            >
              <Select placeholder="Select Activation Mode" dropdownStyle={customStyle.input} name="IINActivationMode" value={customerDetails.IINActivationMode} onChange={(value) => setCustomerDetails(prev => ({ ...prev, IINActivationMode: value }))}>
                <Option value="activation1">Activation 1</Option>
                <Option value="activation2">Activation 2</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <Button type="primary" block onClick={handleSubmit}>Submit</Button>
      </>
    );
  };

  return (
    <div style={customStyle.formContainer}>
      <Form form={form} onFinish={handleSubmit}>
        {getFields()}
      </Form>
    </div>
  );
};

export default CustomerForm;
