// api.js

export const makeApiRequest = async (endpoint, payload) => {
    const url = `https://uat.nsenmf.com/NMFIITrxnService/NMFTrxnService/${endpoint}`; // Replace with actual base URL
    const headers = {
      'Content-Type': 'application/xml',
    };
    
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: payload,
      });
      const result = await response.json(); // Parse response as JSON or modify as needed
      return result;
    } catch (error) {
      throw new Error('API request failed: ' + error.message);
    }
  };
  