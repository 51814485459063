// MultiStepForm.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Spin, notification } from "antd";
import { UserOutlined, InfoCircleOutlined, PhoneOutlined, BankOutlined, FileDoneOutlined } from "@ant-design/icons";
import CustomerForm from "../../components/customer/CustomerDetails";
import NRIForm from "../../components/nri/NriDetails";
import ContactForm from "../../components/contact/ContactDetails";
import BankForm from "../../components/bank/BankDetails";
import FatcaForm from "../../components/fatca/FatcaDetails";
import "../../styles/MultiStepform.css";
import { submitFormData } from "../../redux/actions/customerActions";

const MultiStepForm = () => {
  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);  // Added loading state
  const dispatch = useDispatch();

  const next = () => setCurrent((prev) => prev + 1);
  const prev = () => setCurrent((prev) => prev - 1);

  const updateFormData = (stepData) => {
    setFormData((prevData) => ({
      ...prevData,
      ...stepData,
    }));
  };

  const handleFinalSubmit = async () => {
    // Start the loading spinner
    setLoading(true);
  
    try {
      // Log all form data before submitting
      console.log("All form data:", formData);
  
      // Dispatch the action to submit the form data to the API
      const response = await dispatch(submitFormData(formData));
  
      // Check if the response is successful (success === true)
      if (response.success) {
        // On success, show a notification and log success message
        notification.success({
          message: "Submission Successful",
          description: "Your details have been submitted successfully.",
        });
        console.log("Data submitted successfully!");
      } else {
        // In case the success flag is not true, show an error notification
        notification.error({
          message: "Submission Failed",
          description: response.message || "There was an error submitting your details. Please try again.",
        });
        console.error("Error submitting data:", response.message);
      }
  
    } catch (error) {
      // On error, show error notification
      notification.error({
        message: "Submission Failed",
        description: "There was an error submitting your details. Please try again.",
      });
      console.error("Error submitting data:", error);
    } finally {
      // Stop the loading spinner after submission is done
      setLoading(false);
    }
  };
  

  const stepsData = [
    {
      title: "Customer Details",
      icon: <UserOutlined />,
      content: (
        <div>
          <CustomerForm updateFormData={updateFormData} />
          <div className="form-actions">
            <Button type="primary" onClick={next}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "NRI Details",
      icon: <InfoCircleOutlined />,
      content: (
        <div>
          <NRIForm updateFormData={updateFormData} />
          <div className="form-actions">
            <Button onClick={prev} style={{ marginRight: "8px" }}>
              Previous
            </Button>
            <Button type="primary" onClick={next}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Contact Details",
      icon: <PhoneOutlined />,
      content: (
        <div>
          <ContactForm updateFormData={updateFormData} />
          <div className="form-actions">
            <Button onClick={prev} style={{ marginRight: "8px" }}>
              Previous
            </Button>
            <Button type="primary" onClick={next}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Bank Details",
      icon: <BankOutlined />,
      content: (
        <div>
          <BankForm updateFormData={updateFormData} />
          <div className="form-actions">
            <Button onClick={prev} style={{ marginRight: "8px" }}>
              Previous
            </Button>
            <Button type="primary" onClick={next}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "FATCA Details",
      icon: <FileDoneOutlined />,
      content: (
        <div>
          <FatcaForm updateFormData={updateFormData} />
          <div className="form-actions">
            <Button onClick={prev} style={{ marginRight: "8px" }}>
              Previous
            </Button>
            <Button type="primary" onClick={next}>
              Next
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Final Submit",
      icon: <InfoCircleOutlined />,
      content: (
        <div>
          <h2>Review Your Details</h2>
          <p>Ensure all details are correct before submitting.</p>
          <div className="form-actions">
            <Button onClick={prev} style={{ marginRight: "8px" }}>
              Previous
            </Button>
            <Button type="primary" onClick={handleFinalSubmit} loading={loading}>
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px", maxWidth: "80%", margin: "0 auto" }}>
      {/* Step Header */}
      <div className="steps-header">
        {stepsData.map((step, index) => (
          <div className="step-item" key={index}>
            <div
              className={`step-icon ${current >= index ? "completed" : ""}`}
              style={{ position: "relative" }}
            >
              {step.icon}
              {index < stepsData.length - 1 && (
                <div className={`step-line ${current >= index ? "completed" : ""}`} />
              )}
            </div>
            <div className="step-title">{step.title}</div>
          </div>
        ))}
      </div>

      {/* Step Content */}
      <div className="steps-content">{stepsData[current].content}</div>

      {/* Loader (if loading state is true) */}
      {loading && (
        <div className="loading-overlay">
          <Spin size="large" tip="Submitting data..." />
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
