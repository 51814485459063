import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Select, Typography, theme } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const ContactForm = () => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({}); // State to store form data

  // Custom styles
  const customStyle = {
    formContainer: {
      background: token.colorFillAlter,
      borderRadius: token.borderRadiusLG,
      padding: '10px',
    },
    input: {
      fontFamily: 'Poppins',
      fontSize: '12px',
    },
    heading: {
      padding: '10px 0',
      background: token.colorFillSecondary,
      borderRadius: token.borderRadiusLG,
      textAlign: 'right',
      padding: '12px',
      maxWidth: '100%',
      margin: '12px'
    },
    title: {
      margin: 0,
      fontFamily: 'Poppins',
      fontSize: '14px'
    },
    label: {
      fontFamily: 'Poppins',
      fontSize: '12px'
    },
  };

  // Fetch form data when component loads (this could be from an API, or props)
  const loadFormData = () => {
    // Example of pre-filling the form with static data or data from props/API
    const savedData = {
      address1: "123 Main Street",
      city: "New York",
      state: "NY",
      country: "USA",
      pincode: "10001",
      phone: "123-456-7890",
    };
    setFormData(savedData); // Set form data into state
    form.setFieldsValue(savedData); // Fill the form with existing data
  };

  // Update state with form values when submitted
  const onFinish = (values) => {
    setFormData(values); // Save form data to state
    console.log('Form submitted with data: ', values);
  };

  // UseEffect to load form data when the component mounts
  useEffect(() => {
    loadFormData();
  }, []);

  const getFields = () => {
    return (
      <>
        {/* Heading Row */}
        <Row justify="space-between" style={customStyle.heading}>
          <Col>
            <Text level={4} style={customStyle.title}>Contact Details</Text>
          </Col>
          <Col>
            <Text style={customStyle.stepText}>Step 3 of 6</Text>
          </Col>
        </Row>

        {/* Address Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="address1"
              label={<span style={customStyle.label}>Address 1</span>}
              rules={[{ required: true, message: 'Please input Address 1!' }]}
            >
              <Input placeholder="Address 1" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address2"
              label={<span style={customStyle.label}>Address 2</span>}
            >
              <Input placeholder="Address 2" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Address Row 2 */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="address3"
              label={<span style={customStyle.label}>Address 3</span>}
            >
              <Input placeholder="Address 3" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label={<span style={customStyle.label}>City</span>}
              rules={[{ required: true, message: 'Please input City!' }]}
            >
              <Input placeholder="City" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* State and Country Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="state"
              label={<span style={customStyle.label}>State</span>}
              rules={[{ required: true, message: 'Please input State!' }]}
            >
              <Input placeholder="State" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              label={<span style={customStyle.label}>Country</span>}
              rules={[{ required: true, message: 'Please select Country!' }]}
            >
              <Select placeholder="Select Country" style={customStyle.input}>
                <Option value="india">India</Option>
                <Option value="usa">USA</Option>
                <Option value="uk">UK</Option>
                {/* Add more country options as needed */}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Pincode Row */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="pincode"
              label={<span style={customStyle.label}>Pincode</span>}
              rules={[{ required: true, message: 'Please input Pincode!' }]}
            >
              <Input placeholder="Pincode" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Mobile No */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={<span style={customStyle.label}>Mobile No</span>}
              rules={[{ required: true, message: 'Please input Mobile No!' }]}
            >
              <Input placeholder="Mobile No" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phonerelation"
              label={<span style={customStyle.label}>Mobile Relationship</span>}
              rules={[{ required: true, message: 'Please input Mobile Relationship!' }]}
            >
              <Input placeholder="Mobile Relationship" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Residence and Office Phones */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="resiphone"
              label={<span style={customStyle.label}>Residence Phone</span>}
              rules={[{ required: true, message: 'Please input Residence Phone!' }]}
            >
              <Input placeholder="Residence Phone" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="resifax"
              label={<span style={customStyle.label}>Residence Fax</span>}
              rules={[{ required: true, message: 'Please input Residence Fax!' }]}
            >
              <Input placeholder="Residence Fax" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        {/* Office Phone and Fax */}
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="officephone"
              label={<span style={customStyle.label}>Office Phone</span>}
              rules={[{ required: true, message: 'Please input Office Phone!' }]}
            >
              <Input placeholder="Office Phone" style={customStyle.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="officefax"
              label={<span style={customStyle.label}>Office Fax</span>}
              rules={[{ required: true, message: 'Please input Office Fax!' }]}
            >
              <Input placeholder="Office Fax" style={customStyle.input} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </>
    );
  };

  return (
    <Form form={form} onFinish={onFinish} style={customStyle.formContainer}>
      {getFields()}
    </Form>
  );
};

export default ContactForm;
