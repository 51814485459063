// reducers.js

import {
  SET_FORM_DATA,
  SUBMIT_FORM_REQUEST,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAILURE
} from '../actionTypes/actionTypes';

const initialState = {
  formData: {},
  loading: false,
  error: null,
  success: false,
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case SUBMIT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: null,
      };
    case SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        formData: action.payload,
      };
    case SUBMIT_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default formReducer;
